<div class="input-stage">
	<table>
		<ng-template ngFor let-node [ngForOf]="inputs">
			<tr *ngIf="node.isEnabled() && !!node.getWidget()">
				<td class="input-node">
<!--					<span [class.required]="node.getWidget().required" [class.is-valid]="node.getWidget().isValid()">-->
<!--						{{ node.getTitle() }}:<sup *ngIf="node.getWidget().required && node.getWidget().type !== 'bool'">*</sup>-->
<!--					</span>-->
				</td>
				<td>
					<app-widget [widget]="node.getWidget()" [enabled]="node.isEnabled()"></app-widget>
				</td>
			</tr>
		</ng-template>
	</table>
</div>
