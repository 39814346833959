import { Field } from '../domain/Field';
import { Widget } from '../widget/widget';
import { WidgetFactory } from '../widget/WidgetFactory';
import { SelectType } from './SelectType';
import {DomainType} from "../api/DomainResponse";

export class PresetType extends SelectType {
	private name: string = '';

	constructor(typeData: DomainType) {
		super(typeData);
		this.setRequired(false);
	}

	setName(value: string) {
		this.name = value;
	}

	getName(): string {
		return this.name;
	}

	getEmptyValue(): any {
		return null;
	}

	getDefaultValue(): any {
		return 'UNDEFINED';
	}

	toString(): string {
		return `Preset<${this.name}>`;
	}

	createWidget(field: Field): Widget {
		return WidgetFactory.createChoice(field);
	}
}
