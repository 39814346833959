import { Injectable } from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';


@Injectable({
	providedIn: 'root'
})
export class VisualizerService {

	constructor(private sanitizer: DomSanitizer) { }

	createVisualizerHtml(name: string, args: { [key: string]: string }): SafeHtml {
		let params = [];
		for (let key in args) {
			params.push(`${key}="${args[key]}"`);
		}
		return this.sanitizer.bypassSecurityTrustHtml(`<${name} ${params.join(' ')}/>`);
	}
}
