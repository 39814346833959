import { Context } from '../context.service';
import { TypeResolver } from '../type-resolver.class';
import { ComputeType } from '../types/ComputeType';
import { PresetType } from '../types/PresetType';
import { TypeInfo } from '../types/TypeInfo';
import { SelectWidget } from '../widget/widget.select';
import { Domain } from './Domain';
import { Field } from './Field';
import { Preset } from './Preset';
import {ModelInstance, ValidityState} from "../api/StateResponse";

export class Model {
	private id: string | null = null;
	private meta: Map<string, any> = new Map();
	private fields: Map<string, Field> = new Map();
	private compute: Array<string> = [];

	constructor(private name: string) {
		this.meta.set('title', this.name);
	}

	clone(): Model {
		const instance = new Model(this.name);
		for (let [key, value] of this.meta.entries()) {
			instance.meta.set(key, value); // FIXME: these values are not cloned
		}
		for (let [key, field] of this.fields.entries()) {
			instance.fields.set(key, field.clone(instance));
		}
		instance.compute = [...this.compute];
		return instance;
	}

	get thumbnail(): string | null {
		return this.meta.get('thumbnail');
	}

	getName(): string {
		return this.name;
	}

	getTitle(): string {
		return this.meta.get('title');
	}

	getId(): string | null {
		return this.id;
	}

	setId(id: string) {
		this.id = id;
	}

	getFields(): Map<string, Field> {
		return this.fields;
	}

	getField(fieldName: string): Field | undefined {
		return this.fields.get(fieldName);
	}

	has(fieldName: string): boolean {
		return this.fields.has(fieldName);
	}

	type(fieldName: string): TypeInfo | undefined {
		const field = this.fields.get(fieldName);
		return field ? field.getType() : undefined;
	}

	getMetadata(): Map<string, any> {
		return this.meta;
	}

	getMetadataValue(key: string, defaultValue: any = undefined): any {
		if (this.meta.has(key)) {
			return this.meta.get(key);
		}
		return defaultValue;
	}

	getComputeFields(): Array<string> {
		return this.compute;
	}

	fromJSON(fields: { [key: string]: any; }, validityStates: { [key: string]: ValidityState } | undefined) {
		for (let key in fields) {
			const field = this.fields.get(key);
			if (!field) {
				// ignore unknown fields
				console.warn(`Model.fromJSON: unknown field "${key}"`);
				continue;
			}
			field.setValue(fields[key]);
			if (validityStates && key in validityStates) {
				field.getWidget().setValidityState(validityStates[key]);
			}
		}
	}

	toJSON(): { [key: string]: any; } {
		const fields: { [key: string]: any; } = {};
		for (let [key, field] of this.fields.entries()) {
			fields[key] = field.getValue();
		}
		return fields;
	}

	serialize(): ModelInstance {
		if (!this.id) {
			throw new Error('Unable to serialize Model instance without ID');
		}

		return {
			id: this.id,
			name: this.name,
			fields: this.toJSON(),
		}
	}

	load(domain: Domain, data: any) {
		if (!data)
			return;

		const { fields, meta, name } = data;
		if (!fields || !meta || !name)
			return;

		this.name = name;

		if (Array.isArray(meta) || typeof(meta) !== 'object') {
			console.warn(`Unexpected metadata structure in ${this.name}: ${meta}`);
		}
		else {
			Object.keys(meta).map(k => this.meta.set(k, meta[k]));
		}

		for (let key in fields) {
			const fieldData = fields[key];
			const typeInfo: TypeInfo = TypeResolver.resolve(fieldData);
			const field = new Field(this, key, typeInfo);
			field.setValue(fieldData.value);

			if ('label' in fieldData && fieldData.label) {
				field.setLabel(fieldData.label);
			}

			if ('enabled' in fieldData && typeof(fieldData.enabled) == 'boolean') {
				field.setEnabled(fieldData.enabled);
			}

			this.fields.set(key, field);

			if (typeInfo instanceof ComputeType)
				this.compute.push(key);

			if (typeInfo instanceof PresetType) {
				const presetType = typeInfo as PresetType;
				const preset: Preset | undefined = domain.preset(presetType.getName());
				if (!preset) {
					throw new Error(`Unknown preset '${presetType.getName()}' used by ${this.name}.${key}`);
				}

				const presetOptions = Array.from(preset.getOptions());
				presetType.setOptions(presetOptions);
				(<SelectWidget>field.getWidget()).options = presetOptions.map((item: string) => {
					// TODO: option titling
					return { key: item, value: item, enabled: true };
				});
			}
		}
	}

	register(context: Context) {
		for (let key of this.compute) {
			const computeType = this.type(key) as ComputeType;
			for (let trig of computeType.getTriggers()) {
				context.register(trig.condition, trig.model, trig.field, key);
			}
		}
	}
}
