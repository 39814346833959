import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {SceneComponent} from "../scene/SceneComponent";
import {Node, NodeType} from "../nodes/Node";
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import {GroupNode} from "../nodes/GroupNode";
import {MatStepper} from "@angular/material/stepper";
import {ModelNode} from "../nodes/ModelNode";
import {Context} from "../context.service";
import {Field} from "../domain/Field";
import {FormSimpleComponent} from "../form-simple/form-simple.component";
import {PatchRequest} from "../api/PatchRequest";
import {APIService} from "../api.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ConfiguratorService} from "../configurator.service";
import {VisualizerService} from "../visualizer.service";

export interface WizardStep {
	root: Node | null;
	model: ModelNode;
	index: number;
	completed: boolean;
	component?: FormSimpleComponent;
}

@Component({
	selector: 'app-form-wizard',
	templateUrl: './form-wizard.component.html',
	styleUrls: ['./form-wizard.component.scss']
})
export class FormWizardComponent implements OnInit, AfterViewInit, SceneComponent {
	private node: Node | null = null;

	@ViewChildren(FormSimpleComponent) stepComponents: FormSimpleComponent[] = [];

	public finished: boolean = false;
	public valid: boolean = false;
	public steps: WizardStep[] = [];
	public stepIndex: number = 0;
	public firstStepIndex: number = 0;
	public lastStepIndex: number = 0;
	public visualizers: SafeHtml[] = [];
	public visualizerPosition: string = 'top';

	@ViewChild('stepper') stepper: MatStepper | null = null;

	constructor(
		private configurator: ConfiguratorService,
		private context: Context,
		private api: APIService,
		private visualizer: VisualizerService,
		private sanitizer: DomSanitizer)
	{
		// Called when field changes as a result of interaction
		this.context.onValidate().subscribe((_field: Field) => {
			const step = this.getStepByStepperIndex(this.stepIndex);
			if (step) {
				FormWizardComponent.validateStep(step);
			}
		});

		// Called when state changes as a result of a PATCH
		this.context.onChange().subscribe(() => {
			this.update();

			this.api.sendMessage({
				type: 'konfoo',
				cmd: 'change',
				params: {
					session: this.context.getSessionId(),
					state: this.context.getState(),
				},
			});
		});
	}

	ngOnInit(): void {
		if (this.node) {
			this.collectSteps();

			if (this.node instanceof ModelNode) {
				const model = (<ModelNode>this.node).getModel();
				this.visualizerPosition = model.getMetadataValue('visualizerPosition', 'top');
				const visualizers = model.getMetadata().get('visualizers');
				if (visualizers) {
					this.initializeVisualizers(visualizers);
				}
			}
		}
	}

	ngAfterViewInit() {
		// Deferred to next tick so that Angular can update the view in peace.
		setTimeout(() => {
			this.update();
		}, 0);
	}

	setNode(node: Node): void {
		this.node = node;
	}

	validate(): boolean {
		this.valid = false;
		for (let step of this.steps) {
			FormWizardComponent.validateStep(step);
			if (!step.completed) {
				return false;
			}
		}
		this.valid = true;
		return this.valid;
	}

	serialize(): PatchRequest[] {
		return []; // TODO: serialize all steps
	}

	async onStepperSelect(event: StepperSelectionEvent) {
		this.stepIndex = event.selectedIndex;
		const prev = this.getStepByStepperIndex(event.previouslySelectedIndex);
		if (prev) {
			await this.save(prev);
			FormWizardComponent.validateStep(prev);
		}
		this.update();
	}

	onStepCreated(step: WizardStep, component: FormSimpleComponent) {
		step.component = component;
	}

	update() {
		this.firstStepIndex = this.getFirstStepIndex();
		this.lastStepIndex = this.getLastStepIndex();

		const step = this.getStepByStepperIndex(this.stepIndex);
		if (step) {
			FormWizardComponent.validateStep(step);
		}
	}

	async save(step: WizardStep) {
		const patches = FormWizardComponent.serializeStep(step);
		// console.log('form-wizard saving:', JSON.stringify(patches, null, 4));
		await this.context.changed(patches);

		// execute ui events
		step.component?.execute();
	}

	async finish() {
		this.finished = false;
		this.valid = false;

		const current = this.getStepByStepperIndex(this.stepIndex);
		if (current) {
			await this.save(current);
		}

		if (!this.validate()) {
			console.log('State did not validate entirely');
			return;
		}

		// Finished UI state only when in standalone mode
		if (this.configurator.isStandalone()) {
			this.finished = true;
		}

		this.api.sendMessage({
			type: 'konfoo',
			cmd: 'finish',
			params: {
				session: this.context.getSessionId(),
			},
		});
		console.log('[+] Wizard: finish');
	}

	getFirstStepIndex(): number {
		for (let i = 0; i < this.steps.length; ++i) {
			const step = this.steps[i];
			if (step.root && !step.root.isEnabled())
				continue;
			return i;
		}
		return -1;
	}

	getLastStepIndex(): number {
		for (let i = this.steps.length - 1; i >= 0; --i) {
			const step = this.steps[i];
			if (step.root && !step.root.isEnabled())
				continue;
			return i;
		}
		return -1;
	}

	getStepByStepperIndex(index: number): WizardStep | null {
		let offset = 0;
		for (let step of this.steps) {
			// Skip disabled steps
			if (step.root && !step.root.isEnabled())
				continue;

			if (offset === index) {
				return step;
			}
			offset++;
		}
		return null;
	}

	onResize(): void {}

	private collectSteps() {
		this.steps.length = 0;
		for (let node of this.node!.getSubnodes()) {
			if (node instanceof GroupNode) {
				const step = {
					root: node,
					model: this.node as ModelNode,
					index: -1,
					completed: false,
				};
				this.steps.push(step);
				step.index = this.steps.length - 1;
			}
		}

		this.firstStepIndex = this.getFirstStepIndex();
		this.lastStepIndex = this.getLastStepIndex();
	}

	private initializeVisualizers(visualizers: any) {
		for (let key in visualizers) {
			console.log('[+] loading visualizer: %s', key);
			const name = key;
			const url = typeof(visualizers[key]) === 'string' ? visualizers[key] : visualizers[key].url;

			// Create params
			const params = typeof(visualizers[key]) === 'string' ? {} : visualizers[key];
			delete params.url;

			// Override params set by system
			params.session = this.context.getSessionId();
			params.api = this.api.getFullUrl();

			const script = document.createElement('script');
			script.setAttribute('type', 'module');
			script.setAttribute('src', url);
			script.onload = () => {
				console.log('[+] component loaded: %s', name);
				this.visualizers.push(this.visualizer.createVisualizerHtml(name, params));

				// NOTE: this is a fallback for the request/response API
				//       to ensure that the component gets some sort of state before
				//       any user interaction even if it does not request one specifically
				setTimeout(() => {
					this.api.sendMessage({
						type: 'konfoo',
						cmd: 'change',
						params: {
							session: this.context.getSessionId(),
							state: this.context.getState(),
						},
					});
				}, 100);
			};
			document.body.appendChild(script);
		}
	}

	private static validateStep(step: WizardStep): boolean {
		step.completed = false;
		if (!step.component)
			return false;
		step.completed = step.component.validate();
		return step.completed;
	}

	private static serializeStep(step: WizardStep): PatchRequest[] {
		if (!step.component)
			return [];
		return step.component.serialize();
	}
}
