<div class="logo" (dblclick)="toggleShowSessionID()"></div>

<div *ngIf="standaloneMode" class="controls">
<!--	<select *ngIf="debugMode" [(ngModel)]="selectedDemo" style="margin-right: 20px;">-->
<!--		<option *ngFor="let demo of DEMOS | keyvalue" [value]="demo.key">{{ demo.value.title }}</option>-->
<!--	</select>-->
	<button *ngIf="debugMode" class="btn-run" (click)="load(null)">Load</button>

	<input *ngIf="requireKey" class="input-client-id" type="text" [(ngModel)]="clientId" placeholder="{{ 'CLIENT_ID' | translate }}" />&nbsp;
	<button class="btn-run" (click)="newSession()" [disabled]="requireKey && !clientId">{{ 'NEW' | translate }}</button>&nbsp;
	<input class="input-session-id" type="text" [(ngModel)]="sessionId" placeholder="{{ 'CONFIGURATION_ID' | translate }}" />&nbsp;
	<button class="btn-run" (click)="loadSession()">{{ 'LOAD' | translate }}</button>&nbsp;
</div>
<div *ngIf="!standaloneMode" class="float-right">
	<span *ngIf="showSessionID" class="copy-session-id">{{ sessionId }}</span>
	<button mat-icon-button aria-label="Close" (click)="discard()">
		<mat-icon>close</mat-icon>
	</button>
</div>
<div class="container" [ngSwitch]="viewMode">
<!--	<app-output *ngIf="debugMode"></app-output>-->
	<app-multi-root
		*ngSwitchCase="ViewMode.PROMPT_ROOT_MODEL"
		(rootSelected)="onRootModelSelected($event)">
	</app-multi-root>
	<app-main *ngSwitchCase="ViewMode.WIZARD"></app-main>
</div>
