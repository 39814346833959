import {Model} from '../domain/Model';
import {Executor} from '../exec/Executor';
import {Node, NodeType, SerializedType} from './Node';
import {StateResponse} from "../api/StateResponse";

export class ExecNode extends Node {
	private exe: Executor = new Executor();

	constructor(
		protected model: Model,
		protected parent: Node,
		protected fn: string
	) {
		super(NodeType.EXEC, parent, model);
		this.exe.setSource(fn);
	}

	getFunctionSource(): string {
		return this.exe.getSource() || '';
	}

	execute(context: StateResponse, controller: any = null): any {
		try {
			return this.exe.execute(this.getModel(), context, controller);
		}
		catch (err) {
			console.warn(`ExecNode: caught exception while computing: ${this.model.getName()}`);
			console.warn(err);
			return null;
		}
	}

	toString(): string {
		return `ExecNode(model=${this.model.getName()})`;
	}

	serialize(): SerializedType {
		return {
			key: this.toString(),
			value: this.fn,
		};
	}
}
