import { Context } from '../context.service';
import { Model } from './Model';
import { Preset } from './Preset';
import {DomainResponse} from "../api/DomainResponse";
import {ModelInstance, ModelRef} from "../api/StateResponse";

export type ModelType = () => Model;

export class Domain {
	private root!: string;
	private roots: string[] = [];
	private rootModels: Model[] = [];
	private models: Map<string, Model> = new Map();
	private presets: Map<string, Preset> = new Map();
	private _allocated: Model[] = [];

	constructor(private data: DomainResponse, private context: Context) {
		this.loadDomainModels();
	}

	get(name: string, managed: boolean = true): ModelType | undefined {
		if (!this.models.has(name))
			return undefined;

		return () => {
			const instance = (<Model>this.models.get(name)).clone();
			if (managed) {
				this._allocated.push(instance);
			}
			return instance;
		};
	}

	deserialize(data: ModelInstance): Model {
		const modelType = this.get(data.name);
		if (!modelType) {
			throw new Error(`Cannot deserialize Model: unknown type: ${data.name}`);
		}

		const model = modelType();
		console.log(`[*] Allocated instance: ${data.name} (${data.id})`);
		model.setId(data.id);
		model.fromJSON(data.fields, data.validity);
		return model;
	}

	preset(name: string): Preset | undefined {
		return this.presets.get(name);
	}

	allocated(): Model[] {
		return this._allocated;
	}

	isValid(): boolean {
		return this.rootModels.length > 0 && !!this.root;
	}

	getRootName(): string {
		return this.root;
	}

	isMultiRoot(): boolean {
		return this.roots.length > 1;
	}

	getRoots(): Model[] {
		return this.rootModels;
	}

	setRoot(modelName: string) {
		if (this.roots.indexOf(modelName) === -1) {
			throw new Error(`Invalid root model: ${modelName}`);
		}
		this.root = modelName;
	}

	private loadDomainModels() {
		if (!this.data)
			return;

		if (!this.data.root || !this.data.domain) {
			throw new Error(`Unable to load domain - unexpected structure`);
		}

		this.roots = this.data.root.map((root) => root.name);

		const data = this.data.domain;
		let keys = Object.keys(data);
		for (let key of keys) {
			const model = new Model(key);
			model.load(this, data[key]);
			model.register(this.context);
			this.models.set(key, model);
		}

		this.rootModels.length = 0;
		for (let name of this.roots) {
			const model = this.models.get(name);
			if (!model) {
				console.warn('Root Model "%s" has no model definition - skipped', name);
				continue;
			}
			this.rootModels.push(model);
		}
	}

	// private loadDomainModelsYaml() {
	// 	if (!this.data)
	// 		return;
	//
	// 	let keys = Object.keys(this.data);
	// 	let presets = null;
	// 	const presetsSection = keys.indexOf('presets');
	// 	if (presetsSection !== -1) {
	// 		keys.splice(presetsSection, 1);
	// 		presets = this.data['presets'];
	// 		for (let name in presets) {
	// 			console.log('[i] Found preset type:', name);
	// 			if (this.presets.has(name)) {
	// 				console.warn(`[!] Duplicate preset ignored: ${name}`);
	// 				continue;
	// 			}
	//
	// 			const preset = new Preset(name);
	// 			preset.load(presets[name]);
	// 			this.presets.set(name, preset);
	// 		}
	// 	}
	//
	// 	for (let key of keys) {
	// 		console.log('[i] Found model:', key);
	// 		const model = new Model(key);
	// 		model.load(this, this.data[key]);
	// 		model.register(this.context);
	// 		this.models.set(key, model);
	// 	}
	// }
}
