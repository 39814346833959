import { TypeInfo } from '../types/TypeInfo';
import { Node } from '../nodes/Node';
import { Model } from './Model';
import { nanoid } from 'nanoid';
import { Widget } from '../widget/widget';

export class Field {
	private readonly id: string;
	private label: string;
	private enabled: boolean;
	private value: any = null;
	private readonly widget: Widget;
	private nodes: Set<Node> = new Set();

	constructor(private model: Model, private name: string, private type: TypeInfo) {
		this.id = nanoid();
		this.label = this.name;
		this.widget = type.createWidget(this);
		this.widget.id = this.id;
		this.enabled = true;
	}

	clone(model: Model): Field {
		const field = new Field(model, this.getName(), this.getType());
		field.setLabel(this.getLabel());
		field.setEnabled(this.isEnabled());
		return field;
	}

	getId(): string {
		return this.id;
	}

	getModel(): Model {
		return this.model;
	}

	getName(): string {
		return this.name;
	}

	getType(): TypeInfo {
		return this.type;
	}

	getWidget(): Widget {
		return this.widget;
	}

	isEnabled(): boolean {
		return this.enabled;
	}

	setEnabled(value: boolean) {
		this.enabled = value;
	}

	getLabel(): string {
		return this.label;
	}

	setLabel(label: string) {
		this.label = label;
	}

	setValue(value: any) {
		this.value = value;
		this.widget.setValue(this.value);
	}

	getValue(): any {
		return this.value;
	}

	getNodes(): Set<Node> {
		return this.nodes;
	}

	canAcceptValue(value: any): boolean {
		if (value === null && !this.type.required)
			return true;
		return this.type.canAcceptValue(value);
	}

	addNodeRef(node: Node) {
		this.nodes.add(node);
	}

	removeNodeRef(node: Node) {
		this.nodes.delete(node);
	}

	onChange(_event: any) {
		this.value = this.widget.getValue();
	}

	toString(): string {
		return `Field(${this.model.getName()}.${this.name}, type=${this.type.toString()})`;
	}
}
