import { InputWidget } from './widget.input';

export class IntWidget extends InputWidget {
	type: string = 'int';

	constructor(options: {
		value?: number | null;
		name?: string;
		label?: string;
		required?: boolean;
	} = {}) {
		super({...options, ...{inputType: 'number'}});
	}

	getValue(): number | null {
		if (this.value === null)
			return null;
		return parseInt(this.value);
	}
}

export class NumberWidget extends InputWidget {
	type: string = 'number';
	minValue!: number;
	maxValue!: number;
	step!: number;

	constructor(options: {
		value?: number | null;
		name?: string;
		label?: string;
		required?: boolean;
		minValue?: number;
		maxValue?: number;
		step?: number;
	} = {}) {
		super({...options, ...{inputType: 'number'}});


		this.minValue = options.minValue === undefined ? -Infinity : options.minValue;
		this.maxValue = options.maxValue === undefined ? Infinity : options.maxValue;
		this.step = options.step || 1;

		if (this.minValue > this.maxValue) {
			throw new Error('RangeIntWidget: minimum value is larger than maximum value');
		}
	}

	getValue(): number | null {
		if (this.value === null)
			return null;
		return this.value;
	}
}
