import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Widget } from './widget';
import { ColorWidget } from './widget.color';
import { InputWidget } from './widget.input';
import { NumberWidget } from './widget.number';
import {GalleryWidget, SearchWidget, SelectWidget} from './widget.select';
import {ListWidget} from "./widget.list";
import {InputNode} from "../nodes/InputNode";

@Component({
	selector: 'app-widget',
	templateUrl: './widget.component.html',
	styleUrls: ['./widget.component.scss']
})
export class WidgetComponent {
	@Input() widget!: Widget;
	@Input() form!: FormGroup;
	@Input() enabled: boolean = true;

	constructor() { }

	get isValid() {
		return this.form.controls[this.widget.name].valid;
	}

	getSelectWidget(): SelectWidget {
		return this.widget as SelectWidget;
	}

	getSearchWidget(): SearchWidget {
		return this.widget as SearchWidget;
	}

	getGalleryWidget(): GalleryWidget {
		return this.widget as GalleryWidget;
	}

	getInputWidget(): InputWidget {
		return this.widget as InputWidget;
	}

	getColorWidget(): ColorWidget {
		return this.widget as ColorWidget;
	}

	getNumberWidget(): NumberWidget {
		return this.widget as NumberWidget;
	}

	getListWidget(): ListWidget {
		return this.widget as ListWidget;
	}

	getListWidgetDescription(ref: string): string {
		const modelNode = this.getListWidget()?.getModelNodeByRef(ref);
		if (!modelNode)
			return '';
		const subnodes = modelNode.getSubnodes();
		if (subnodes.length == 0)
			return '';

		for (let node of subnodes) {
			if (node instanceof InputNode) {
				const value = (node as InputNode).getField().getValue();
				if (value == null)
					return modelNode.getModel() ? modelNode.getModel().getTitle() : '';
				return `${node.getTitle()}: ${value}`;
			}
		}

		return modelNode.getModel() ? modelNode.getModel().getTitle() : '';
	}

	getStyleString(): string {
		const node = this.widget.getInputNode();
		if (!node) {
			return '';
		}
		return node.getStyleString();
	}
}
