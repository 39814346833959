import { Model } from '../domain/Model';
import { Node, NodeType, SerializedType } from './Node';
import {GridData, GridForm, SceneRenderer, WizardData} from "../api/SceneGraphResponse";
import {SceneComponent} from "../scene/SceneComponent";

export class ModelNode extends Node {
	protected scene: GridData | WizardData | null = null;
	protected component?: SceneComponent;

	constructor(
		protected model: Model,
		protected parent: Node | null = null,
		protected renderer: string = SceneRenderer.WIZARD
	) {
		super(NodeType.MODEL, parent, model);
	}

	toString(): string {
		return `ModelNode(model=${this.model.getName()}, id=${this.model.getId()}, renderer=${this.renderer})`;
	}

	serialize(): SerializedType {
		const data: any = {};
		for (let [key, field] of this.model.getFields().entries()) {
			data[key] = field.getType().getInitialValue();
		}
		return {
			key: this.model.getName(),
			value: data
		};
	}

	getRenderer(): string {
		return this.renderer;
	}

	setRenderer(renderer: SceneRenderer) {
		this.renderer = renderer;
	}

	getScene(): GridData | WizardData | null {
		return this.scene;
	}

	setScene(scene: GridData | WizardData | null) {
		this.scene = scene;
	}

	getGridForm(): GridForm | null {
		if (this.renderer !== SceneRenderer.GRID)
			return null;
		if (this.scene && this.model.getName() in this.scene) {
			return this.scene[this.model.getName()];
		}
		return null;
	}

	setComponent(component: SceneComponent) {
		this.component = component;
	}

	getComponent(): SceneComponent | undefined {
		return this.component;
	}
}
