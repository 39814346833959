import {GridStackOptions} from "gridstack";

export enum SceneRenderer {
	WIZARD = 'wizard',
	SIMPLE = 'simple',
	GRID = 'grid',
}

export interface GridSection {
	title: string;
	grid: any[];
	initially_open: boolean;
	options?: GridStackOptions;
	__ui_open?: boolean;
}

export interface GridForm {
	model: string;
	sections: GridSection[];
}

export type WizardData = { [key: string]: any; };
export type GridData = { [key: string]: GridForm; };

export interface SceneGraphResponse {
	renderer: SceneRenderer;
	scene: WizardData | GridData;
}
