import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ConfiguratorService} from "../configurator.service";
import {Model} from "../domain/Model";

@Component({
	selector: 'app-multi-root',
	templateUrl: './multi-root.component.html',
	styleUrls: ['./multi-root.component.scss']
})
export class MultiRootComponent implements OnInit {
	@Output() rootSelected = new EventEmitter<string>();
	public candidates: Model[] = [];
	public selected: Model | undefined;

	constructor(private configurator: ConfiguratorService) {}

	ngOnInit(): void {
		this.candidates = this.configurator.getDomain().getRoots();
	}

	onChange() {
		if (!this.selected)
			return;
		this.rootSelected.emit(this.selected.getName());
	}

	onRootSelect(model: Model) {
		this.selected = model;
		this.rootSelected.emit(this.selected.getName());
	}
}
