import {QueueItem, SceneGraph} from "./SceneGraph";
import {Domain} from "../domain/Domain";
import {Context} from "../context.service";
import {ComponentRef, ViewContainerRef} from "@angular/core";
import {SceneComponent} from "./SceneComponent";
import {ModelNode} from "../nodes/ModelNode";
import {GridForm, SceneRenderer} from "../api/SceneGraphResponse";
import {FormGridComponent} from "../form-grid/form-grid.component";
import {InputNode} from "../nodes/InputNode";
import {GridStackOptions} from "gridstack";
import {Node, NodeType} from "../nodes/Node";
import {Model} from "../domain/Model";


const DEFAULT_GRID_UNIT_HEIGHT = 80;

export class SceneGraphGrid extends SceneGraph {
	public rootForm: GridForm | null = null;

	constructor(protected domain: Domain, protected context: Context) {
		super(domain, context);
	}

	load(data: any) {
		this.sceneData = data;
		(<ModelNode>this.root()).setScene(this.sceneData);

		const rootModel = this.root().getModel();
		const rootLogic = this.getFormLogic(rootModel.getName());
		if (!rootLogic) {
			throw new Error(`Root domain entity '${rootModel.getName()}' not defined in form logic`);
		}
		this.rootForm = <GridForm>rootLogic;
		this.addNode(this.root(), rootLogic);
	}

	addNode(node: Node, logic: any) {
		if (!logic)
			return;

		const queue: QueueItem[] = [{ node, logic }];
		while (queue.length > 0) {
			const { node, logic } = <QueueItem>queue.shift();
			if (!logic)
				continue;

			const model: Model = <Model>node.getModel();
			const form = <GridForm>logic;

			for (let section of form.sections) {
				// Override editing options
				section.options = this.getDefaultGridOpts();
				section.__ui_open = section.initially_open;

				// Create input nodes
				for (let widget of section.grid) {
					const subnode = SceneGraph.createNode(
						'input', node, model, this.getInputNodeData(model, widget));
					subnode.setContext(this.context);

					let items;
					switch (subnode.getType()) {
						case NodeType.INPUT:
							items = this.processInputNode(node, subnode as InputNode);
							queue.push(...items);
							break;

						case NodeType.GROUP:
							// items = SceneGraph.processGroupNode(node, subnode as GroupNode);
							// queue.push(...items);
							break;

						case NodeType.META:
							break;

						case NodeType.MODEL:
							// node.add(subnode);
							break;

						case NodeType.EXEC:
							// node.add(subnode);
							break;

						default:
							console.warn(`Node type has no implementation: ${subnode.getType()}`);
							break;
					}
				}
			}
		}
	}

	getRendererComponent(viewContainerRef: ViewContainerRef): ComponentRef<SceneComponent> {
		if (!(this.root() instanceof ModelNode)) {
			throw new Error('Root node is not ModelNode');
		}

		const component = viewContainerRef.createComponent(FormGridComponent);
		if (this.rootForm) {
			component.instance.setGridForm(this.rootForm);
		}

		return component;
	}

	protected createRootNode(): ModelNode {
		const node = new ModelNode(this.context.getRootInstance(), null, SceneRenderer.GRID);
		node.setContext(this.context);
		node.setScene(this.sceneData);
		return node;
	}

	protected getDefaultModelRenderer(): SceneRenderer {
		return SceneRenderer.GRID;
	}

	private getInputNodeData(model: Model, widget: any) {
		return {
			title: model.getField(widget.field)?.getLabel(),
			field: widget.field,
			width: '100%',
		};
	}

	private getDefaultGridOpts(): GridStackOptions {
		return {
			margin: 4,
			cellHeight: DEFAULT_GRID_UNIT_HEIGHT,
			acceptWidgets: false,
			staticGrid: true,
			children: [],
			styleInHead: true,
			// sizeToContent: true,
		};
	}
}
