import * as ListParser from './list-parser';
import * as PresetParser from './preset-parser';
import * as RangeParser from './range-parser';
import { Bool } from './types/Bool';
import {ColorRGB, ColorRGBA} from './types/Color';
import { ComputeType } from './types/ComputeType';
import { DateType } from './types/DateType';
import { ListType } from './types/ListType';
import { NumericType, PositiveInteger } from './types/Numeric';
import { PresetType } from './types/PresetType';
import { SelectType } from './types/SelectType';
import { TextType } from './types/Text';
import { TypeInfo } from './types/TypeInfo';
import {DomainField, DomainType} from "./api/DomainResponse";

export interface ListParserResult {
	op: string;
	types: string[];
	size: number | null;
}

export interface RangeParserResult {
	op: string;
	min: number;
	max: number;
	step: number;
}

export interface PresetParserResult {
	op: string;
	name: string;
}


export class TypeResolver {
	static resolve(data: DomainField): TypeInfo {
		const info = TypeResolver.resolveDomain(data.type);
		info.setRequired(data.required);
		return info;
	}

	static resolveDomain(type: DomainType): TypeInfo {
		switch (type.name.toLowerCase()) {
			case 'rgb': return new ColorRGB();
			case 'rgba': return new ColorRGBA();
			case 'positiveinteger': return new PositiveInteger();
			case 'numeric': {
				const instance = new NumericType();
				instance.setMin(type.params['min']);
				instance.setMax(type.params['max']);
				instance.setStep(type.params['step']);
				return instance;
			}
			case 'date': return new DateType();
			case 'choice': return new SelectType(type);
			case 'compute': return new TextType({ name: 'text', params: { multiline: false, readonly: true }});
			case 'text': return new TextType(type);
			case 'bool':
			case 'boolean':
				return new Bool();
			case 'list':
				return new ListType(type);

			default:
				break;
		}

		throw new Error(`Unknown type: ${type.name}`);
	}
}
