import { Pipe, PipeTransform } from '@angular/core';

interface ITranslationSet {
	name: string;
	locale: string;
	map: { [key: string]: string; };
}

export interface ITranslations {
	translations: { [locale: string]: ITranslationSet };
}

const TRANSLATIONS: ITranslations = {
	translations: {
		'en': {
			name: 'English',
			locale: 'en',
			map: {
				BACK: 'Back',
				NEXT: 'Next',
				FINISH: 'Finish',
				NEW: 'New',
				LOAD: 'Load',
				CLIENT_ID: 'Client ID',
				CONFIGURATION_ID: 'Configuration ID',
				SELECT_ROOT: 'Select product',
			}
		},
		'ee': {
			name: 'Eesti',
			locale: 'ee',
			map: {
				BACK: 'Tagasi',
				NEXT: 'Edasi',
				FINISH: 'Lõpeta',
				NEW: 'Uus',
				LOAD: 'Lae',
				CLIENT_ID: 'Kliendi võti',
				CONFIGURATION_ID: 'Konfiguratsiooni ID',
				SELECT_ROOT: 'Vali toode',
			}
		},
	}
};

const DEFAULT_LANG = 'en';

@Pipe({
	name: 'translate'
})
export class TranslatePipe implements PipeTransform {
	// TODO: get current locale from env

	transform(value: any, ...args: unknown[]): any {
		const translations = TRANSLATIONS.translations[DEFAULT_LANG];
		const mapped = translations.map[value];
		return mapped ? mapped : value;
	}
}
