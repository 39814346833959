import { Pipe, PipeTransform } from '@angular/core';
import {WizardStep} from "./form-wizard/form-wizard.component";

@Pipe({
	name: 'activeWizardStep'
})
export class ActiveWizardStepPipe implements PipeTransform {
	transform(value: WizardStep[], ...args: unknown[]): WizardStep[] {
		return value.filter((step) => {
			return !(step.root && !step.root.isEnabled());
		});
	}
}
