import { TypeInfo } from './TypeInfo';
import {DomainType} from "../api/DomainResponse";
import {Field} from "../domain/Field";
import {Widget} from "../widget/widget";
import {WidgetFactory} from "../widget/WidgetFactory";

export class ListType extends TypeInfo {
	private types: Set<string> = new Set()
	private readonly min: number = 0;
	private readonly max: number = 0;
	private readonly allow_add: boolean = true;
	private readonly allow_delete: boolean = true;

	constructor(type: DomainType) {
		super();
		this.min = type.params['min'];
		this.max = type.params['max'];
		this.allow_add = type.params['allow_add'];
		this.allow_delete = type.params['allow_delete'];
		this.addType(type.params['model']);
	}

	canAcceptValue(value: any): boolean {
		return Array.isArray(value);
	}

	canAdd(): boolean {
		return this.allow_add;
	}

	canDelete(): boolean {
		return this.allow_delete;
	}

	addType(type: string) {
		this.types.add(type);
	}

	getTypes(): Set<string> {
		return this.types;
	}

	getMin(): number {
		return this.min;
	}

	getMax(): number {
		return this.max;
	}

	getEmptyValue(): any {
		return [];
	}

	getDefaultValue(): any {
		return [];
	}

	toString(): string {
		return `List<${Array.from(this.types).join('|')}>[${this.min}..${this.max}]`;
	}

	createWidget(field: Field): Widget {
		return WidgetFactory.createList(field);
	}
}
