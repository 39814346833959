import { Model } from '../domain/Model';
import { Node, NodeType } from './Node';

export class GroupNode extends Node {
	constructor(
		protected model: Model,
		protected parent: Node,
		protected groupType: string,
		protected data: any // TODO: interface
	) {
		super(NodeType.GROUP, parent, model);
	}

	isParallel(): boolean {
		return this.groupType === 'parallel';
	}

	getGroupData(): any {
		return this.data;
	}

	toString(): string {
		return `GroupNode(model=${this.model.getName()}, type=${this.groupType})`;
	}
}
