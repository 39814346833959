import {AfterViewInit, Component} from '@angular/core';
import { ConfiguratorService } from './configurator.service';
import {APIService, KonfooMessage} from "./api.service";
import {MatSnackBar} from "@angular/material/snack-bar";

enum ViewMode {
	WIZARD,
	PROMPT_ROOT_MODEL,
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
	public ViewMode = ViewMode;
	public debugMode: boolean = false;
	public standaloneMode: boolean = false; // true if not embedded in iframe
	public sessionId: string | null = null;
	public requireKey: boolean = true;
	public clientId: string | null = null;
	public viewMode: ViewMode = ViewMode.WIZARD;
	public showSessionID: boolean = false;
	private endPrompt: (() => void) | null = null;

	constructor(
		public configurator: ConfiguratorService,
		private api: APIService,
		private snackbar: MatSnackBar
	) {
		this.standaloneMode = this.configurator.isStandalone();
		console.log(`[+] Konfoo - ${this.standaloneMode ? 'Standalone' : 'Embed'} mode`);
		console.log(`[+] View mode: ${this.viewMode}`);

		window.addEventListener('message', (e) => {
			if (typeof e.data === 'object' && e.data.type === 'konfoo') {
				this.onMessage(e.data);
			}
		});

		this.configurator.onReady().subscribe(() => {
			this.api.sendMessage({
				type: 'konfoo',
				cmd: 'ready',
				params: {},
			});
		});

		this.configurator.onLoaded().subscribe(() => {
			this.sessionId = this.configurator.getContext().getSessionId();
			this.api.sendMessage({
				type: 'konfoo',
				cmd: 'start',
				params: {
					session: this.sessionId,
					state: this.configurator.getContext().getState(),
				},
			});
		});

		this.configurator.onPromptRootModel().subscribe(done => {
			this.viewMode = ViewMode.PROMPT_ROOT_MODEL;
			this.endPrompt = done;
		});

		if (this.standaloneMode) {
			const sessionId = localStorage.getItem('sessionId');
			if (sessionId) {
				this.sessionId = sessionId;
			}

			this.api.hello().then(response => {
				this.requireKey = response.requires_key;
			});
		}
	}

	ngAfterViewInit() {
		console.log('[+] Views initialized');

		this.api.sendMessage({
			type: 'konfoo',
			cmd: 'hello',
			params: null,
		});

		this.loadSession().then();
	}

	rebuild() {
		this.configurator.rebuild();
	}

	async load(clientId: string | null) {
		try {
			let logicPath = `${this.api.getUrl()}/form`;
			await this.configurator.load(clientId, logicPath, this.sessionId);
			// if (demo.bom) {
			// 	await this.configurator.addBomGenerator(demo.bom);
			// }

			if (this.configurator.getContext().hasState()) {
				this.sessionId = this.configurator.getContext().getSessionId();
				if (this.standaloneMode) {
					localStorage.setItem('sessionId', this.sessionId);
				}
			}
		}
		catch (e: any) {
			console.error('Load failed: ', e);
			const err = typeof(e) === 'string' ? e : e.statusText || e.toString();
			this.snackbar.open(err, 'Close', {
				duration: 10000
			});
			this.api.sendMessage({
				type: 'konfoo',
				cmd: 'error',
				params: {
					message: err,
					session: this.sessionId
				},
			});
		}
	}

	async newSession() {
		this.sessionId = null;
		localStorage.removeItem('sessionId');
		await this.load(this.clientId);
	}

	async loadSession() {
		if (!this.sessionId || !this.standaloneMode) {
			return;
		}
		localStorage.setItem('sessionId', this.sessionId);
		await this.load(this.clientId);
	}

	async onRootModelSelected(modelName: string) {
		this.configurator.getDomain().setRoot(modelName);
		this.viewMode = ViewMode.WIZARD;
		if (this.endPrompt) {
			this.endPrompt();
			this.endPrompt = null;
		}
	}

	toggleShowSessionID() {
		this.showSessionID = !this.showSessionID;
	}

	discard() {
		this.api.sendMessage({
			type: 'konfoo',
			cmd: 'discard',
			params: {},
		});
	}

	private onMessage(data: KonfooMessage) {
		// console.log('[*] konfoo.recv:', data);
		switch (data.cmd) {
			case 'hello':
				console.log('[+] received hello from parent:', data.params);
				this.api.setParentOrigin(data.params.origin);
				if (data.params.session) {
					this.sessionId = data.params.session;
				}
				this.api.sendMessage({
					type: 'konfoo',
					cmd: 'hello',
					params: null,
				});
				break;
			case 'auth':
				console.log('[+] parent authenticated');
				this.clientId = data.params.key;
				this.load(this.clientId).then(r => {});
				break;
		}
	}
}
