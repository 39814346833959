import { Field } from '../domain/Field';
import { Widget } from '../widget/widget';
import { WidgetFactory } from '../widget/WidgetFactory';
import { TypeInfo } from './TypeInfo';
import {DomainType} from "../api/DomainResponse";

export class TextType extends TypeInfo {
	private multiline: boolean = false
	private readonly: boolean = false
	private _options: string[] = [];

	constructor(typeData: DomainType) {
		super();
		this.multiline = !!(typeData.params['multiline'] || false);
		this.readonly = !!(typeData.params['readonly'] || false);

		if (typeData.params['widget'] === 'text') {
			this.multiline = true;
		}

		if (Array.isArray(typeData.params['options'])) {
			this._options = [...typeData.params['options']];
		}
	}

	canAcceptValue(value: any): boolean {
		return typeof(value) == 'string';
	}

	getDefaultValue(): any {
		return '';
	}

	toString(): string {
		return 'string';
	}

	isReadOnly(): boolean {
		return this.readonly;
	}

	isFreeInput(): boolean {
		return true;
	}

	createWidget(field: Field): Widget {
		if (this.readonly)
			return WidgetFactory.createReadOnly(field);
		if (this.multiline)
			return WidgetFactory.createTextMultiline(field);
		return WidgetFactory.createText(field);
	}

	getOptions(): string[] {
		return this._options;
	}

	setOptions(options: string[]) {
		this._options = options;
	}
}
