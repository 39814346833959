import { Model } from '../domain/Model';
import { Node, NodeType } from './Node';

export class MetaNode extends Node {
	constructor(
		protected model: Model,
		protected parent: Node,
		protected data: any // TODO: interface
	) {
		super(NodeType.META, parent, model);
		for (let key in data) {
			parent.setMetadata(key, data[key]);
		}
	}

	toString(): string {
		return `MetaNode(model=${this.model.getName()})`;
	}
}
