import { InputWidget } from './widget.input';

export class DateWidget extends InputWidget {
	type: string = 'date';
	minValue!: string;
	maxValue!: string;

	constructor(options: {
		value?: string | null;
		name?: string;
		label?: string;
		required?: boolean;
		minValue?: string;
		maxValue?: string;
	} = {}) {
		super({...options, ...{inputType: 'date'}});


		this.minValue = options.minValue === undefined ? '' : options.minValue;
		this.maxValue = options.maxValue === undefined ? '' : options.maxValue;
	}

	getValue(): string | null {
		return this.value;
	}
}
