const FN_LOGGER = `var log = function() {
	var args = Array.prototype.slice.call(arguments);

	if (window && window.__contextHandle) {
		window.__contextHandle.notify(args.join(' '));
	}

	args.unshift('[i]');
	console.log.apply(console, args);
};`

export class Executor {
	protected src: string | null = null;
	protected fn: Function | null = null;

	constructor() {
	}

	getSource(): string | null {
		return this.src;
	}

	setSource(src: string) {
		this.src = src;
		this.compile();
	}

	get ok() {
		return !!this.fn;
	}

	get execute() {
		if (!this.fn) {
			throw new Error('No function to execute');
		}
		return this.fn;
	}

	protected compile() {
		if (!this.src)
			return;

		this.fn = new Function(`
			${FN_LOGGER}

			return ${this.src};`
		)();
	}
}

export class ExpressionExecutor extends Executor {
	protected compile() {
		if (!this.src)
			return;

		this.fn = new Function(`
			return function(context) {
				if (context) {
					var ctx = context.getOutput();
					for (var modelName in ctx) {
						eval('var ' + modelName + '=' + JSON.stringify(ctx[modelName]) + ';');
					}
				}
				return  ${this.src};
			};`
		)();
	}
}
