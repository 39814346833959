import { Widget } from './widget';

export interface InputWidgetOptionType {
	key: string;
	value: string;
	enabled: boolean;
}

export class InputWidget extends Widget {
	type: string = 'input';
	inputType!: string;
	options!: InputWidgetOptionType[];

	constructor(options: {
		value?: any;
		name?: string;
		label?: string;
		required?: boolean;
		inputType?: string;
		options?: InputWidgetOptionType[];
	} = {}) {
		super(options);
		this.inputType = options.inputType || 'text';
		this.options = options.options || [];
	}
}
