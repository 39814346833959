import { ModelType } from '../domain/Domain';
import { Field } from '../domain/Field';
import { Widget } from '../widget/widget';
import { WidgetFactory } from '../widget/WidgetFactory';
import { TypeInfo } from './TypeInfo';
import {DomainType} from "../api/DomainResponse";

export class SelectType extends TypeInfo {
	private readonly _multi: boolean = false;
	private readonly _radio: boolean = false;
	private readonly _search: boolean = false;
	private readonly _gallery: boolean = false;
	private _options: string[] = [];
	private _isModel: Map<string, ModelType> = new Map();
	private thumbnails: Map<string, any> = new Map();

	constructor(typeData: DomainType) {
		super();
		this._multi = !!typeData.params['allow_multiple'];
		if (Array.isArray(typeData.params['options'])) {
			this._options = [...typeData.params['options']];
		}
		this._radio = typeData.params['widget'] === 'radio';
		this._search = typeData.params['widget'] === 'search';
		this._gallery = typeData.params['widget'] === 'gallery';

		if ('thumbnails' in typeData.params) {
			this.thumbnails = {...typeData.params.thumbnails};
		}
	}

	canAcceptValue(value: any): boolean {
		return typeof(value) === 'string' || value === null;
	}

	isRadio(): boolean {
		return this._radio;
	}

	isSearch(): boolean {
		return this._search;
	}

	isGallery(): boolean {
		return this._gallery;
	}

	isMultiSelect(): boolean {
		return this._multi;
	}

	getOptions(): string[] {
		return this._options;
	}

	setOptions(options: string[]) {
		this._options = options;
	}

	setOptionToModel(option: string, model: ModelType) {
		this._isModel.set(option, model);
	}

	isModel(option: string): boolean {
		return this._isModel.has(option);
	}

	hasModelOptions(): boolean {
		return this._isModel.size > 0;
	}

	getEmptyValue(): any {
		return null;
	}

	getDefaultValue(): any {
		return null;
	}

	getThumbnails(): Map<string, any> {
		return this.thumbnails;
	}

	toString(): string {
		return 'select';
	}

	createWidget(field: Field): Widget {
		return WidgetFactory.createChoice(field);
	}
}
