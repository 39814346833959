export interface TraversalOptions<T> {
	subnodesAccessor: (node: T) => T[];
	userdataAccessor?: (node: T, userdata: any) => any;
	onNode: (node: T, next: () => void, userdata: any) => void;
	userdata?: any | undefined;
}

export interface TraversalContext<T> {
	node: T;
	userdata: any;
	level: number;
}

function onNode<T>(options: TraversalOptions<T>, context: TraversalContext<T>): Promise<void> {
	return new Promise<void>(function(resolve, _reject) {
		options.onNode(context.node, resolve, context);
	});
}

export class TreeTraversal {
	static *depthIterator<T>(rootNode: T, options: TraversalOptions<T>): IterableIterator<TraversalContext<T>> {
		const stack: TraversalContext<T>[] = [];
		stack.push({
			node: rootNode,
			userdata: options.userdata,
			level: 0,
		});

		while (stack.length > 0) {
			const top: TraversalContext<T> = <TraversalContext<T>>stack.pop();
			yield top;

			let subnodeData = undefined;
			if (options.userdataAccessor)
				subnodeData = options.userdataAccessor(top.node, top.userdata);

			const subnodes = options.subnodesAccessor(top.node);
			for (let i = subnodes.length - 1; i >= 0; --i) {
				stack.push({
					node: subnodes[i],
					userdata: subnodeData,
					level: top.level + 1,
				});
			}
		}
	}

	static async depth<T>(rootNode: T, options: TraversalOptions<T>) {
		const it: IterableIterator<TraversalContext<T>> = this.depthIterator(rootNode, options);
		for (let item of it) {
			await onNode<T>(options, item);
		}
	}
}
