import {Context} from "../context.service";
import {PatchRequest} from "../api/PatchRequest";
import {ModelInstance, ModelRef} from "../api/StateResponse";
import {Node} from "../nodes/Node";

export class ExecAPI {
	// TODO: this will be eventually replaced by proper sandbox

	private transaction: { [key: string]: PatchRequest } = {};

	constructor(private context: Context, private node: Node) {}

	getRef(ref: ModelRef): ModelInstance | null {
		const state = this.context.getState();
		if (!state)
			return null;
		return state.by_id[ref.__ref__] || null;
	}

	getNode(): Node {
		return this.node;
	}

	setModelValue(modelId: string, modelName: string, fieldName: string, value: any): void {
		if (!(modelId in this.transaction)) {
			this.transaction[modelId] = {
				id: modelId,
				model: modelName,
				patch: {},
			};
		}
		this.transaction[modelId].patch[fieldName] = value;
	}

	commit(): PatchRequest[] {
		const tx = Object.values(this.transaction);
		this.reset();
		return tx;
	}

	reset() {
		this.transaction = {};
	}
}
