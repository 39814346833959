import {Domain} from "../domain/Domain";
import {Context} from "../context.service";
import {Node, NodeType} from "../nodes/Node";
import {ModelNode} from "../nodes/ModelNode";
import {Model} from "../domain/Model";
import {InputNode} from "../nodes/InputNode";
import {GroupNode} from "../nodes/GroupNode";
import {MetaNode} from "../nodes/MetaNode";
import {ExecNode} from "../nodes/ExecNode";
import {TypeInfo} from "../types/TypeInfo";
import {ListType} from "../types/ListType";
import {ComponentRef, ViewContainerRef} from "@angular/core";
import {SceneComponent} from "./SceneComponent";
import {FormWizardComponent} from "../form-wizard/form-wizard.component";
import {FormSimpleComponent} from "../form-simple/form-simple.component";
import {TraversalContext, TreeTraversal} from "../tree-traversal";
import {SceneRenderer} from "../api/SceneGraphResponse";

export interface QueueItem {
	node: Node;
	logic: any; // used to be Array<any>
}

export class SceneGraph {
	private readonly rootNode: Node;
	protected sceneData: any; // TODO: schema

	constructor(protected domain: Domain, protected context: Context) {
		this.rootNode = this.createRootNode();
	}

	root(): Node {
		return this.rootNode;
	}

	getRendererComponent(viewContainerRef: ViewContainerRef): ComponentRef<SceneComponent> {
		if (!(this.rootNode instanceof ModelNode)) {
			throw new Error('Root node is not ModelNode');
		}
		switch (this.rootNode.getRenderer()) {
			case 'wizard': return viewContainerRef.createComponent(FormWizardComponent);
			case 'simple':
			default:
				return viewContainerRef.createComponent(FormSimpleComponent);
		}
	}

	getFormLogic(modelName: string): any | undefined {
		if (!this.sceneData)
			return undefined;
		return this.sceneData[modelName];
	}

	load(data: any) {
		this.sceneData = data;
		(<ModelNode>this.root()).setScene(this.sceneData);

		const rootModel = this.root().getModel();
		const rootLogic = this.getFormLogic(rootModel.getName());
		if (!rootLogic) {
			throw new Error(`Root domain entity '${rootModel.getName()}' not defined in form logic`);
		}
		this.addNode(this.root(), rootLogic);
	}

	addModelNode(model: Model, parent: Node) {
		const node: ModelNode = new ModelNode(model, parent, this.getDefaultModelRenderer());
		node.setContext(this.context);
		node.setScene(this.sceneData);
		parent.add(node);

		const logic = this.getFormLogic(node.getModel().getName());
		if (!logic) {
			throw new Error(`No form logic defined for: ${node.getModel().getName()}`);
		}
		this.addNode(node, logic);
		return node;
	}

	addNode(node: Node, logic: any) {
		if (!logic)
			return;

		const queue: QueueItem[] = [{ node, logic }];
		while (queue.length > 0) {
			const { node, logic } = <QueueItem>queue.shift();
			if (!logic)
				continue;

			const model: Model = <Model>node.getModel();
			for (let step of <Array<any>>logic) {
				const props = Object.keys(step);

				// Ignore anything that does not fit our structure
				if (props.length !== 1)
					continue;

				const nodeType = props[0];
				const subnode = SceneGraph.createNode(nodeType, node, model, step[nodeType]);
				subnode.setContext(this.context);

				let items;
				switch (subnode.getType()) {
					case NodeType.INPUT:
						items = this.processInputNode(node, subnode as InputNode);
						queue.push(...items);
						break;

					case NodeType.GROUP:
						items = SceneGraph.processGroupNode(node, subnode as GroupNode);
						queue.push(...items);
						break;

					case NodeType.META:
						break;

					case NodeType.MODEL:
						node.add(subnode);
						break;

					case NodeType.EXEC:
						node.add(subnode);
						break;

					default:
						console.warn(`Node type has no implementation: ${subnode.getType()}`);
						break;
				}
			}
		}
	}

	// private generateDefaultLogic(model: Model) {
	// 	console.log('[+] Generating default logic:', model.getName());
	// 	const inputs = [];
	// 	for (let [key, _value] of model.getFields().entries()) {
	// 		// TODO: could generate titles based on field type here
	// 		//       in fact, the type classes should already know their default titles
	// 		inputs.push({
	// 			input: { field: key }
	// 		});
	// 	}
	// 	this.data[model.getName()] = inputs;
	// }

	protected processInputNode(parent: Node, subnode: InputNode): QueueItem[] {
		parent.add(subnode);
		const typeInfo: TypeInfo = subnode.getInputType();

		// ListType produces 0..n ModelNode sub-graphs
		if (typeInfo instanceof ListType) {
			return this.processListType(subnode, typeInfo);
		}

		return [];

		// TODO: restore default logic generator
		// TODO: select type nested models
		// if (typeInfo instanceof SelectType) {
		// 	for (let option of typeInfo.getOptions()) {
		// 		// Are we dealing with a Domain Model?
		// 		const mdl: ModelType | undefined = this.domain.get(option);
		// 		if (!mdl) {
		// 			continue;
		// 		}
		//
		// 		typeInfo.setOptionToModel(option, mdl);
		//
		// 		const model = mdl();
		// 		const modelNode: ModelNode = new ModelNode(model, subnode);
		//
		// 		// Generate default logic for filling the Model if no logic has been defined
		// 		// if (!(option in data)) {
		// 		// 	this.generateDefaultLogic(model);
		// 		// }
		//
		// 		subnode.add(modelNode);
		// 		queue.push({ node: modelNode, logic: data[model.getName()] });
		// 	}
		// }
	}

	protected processListType(subnode: InputNode, typeInfo: ListType): QueueItem[] {
		// Are we dealing with a Domain Model? Does it have defined logic?
		const {value, done} = typeInfo.getTypes().values().next();
		if (!value)
			return [];

		const logic = this.getFormLogic(value);
		if (!logic) {
			return [];
		}

		const refs = subnode.getField().getValue();
		if (!refs || !Array.isArray(refs)) {
			return [];
		}

		const queue = [];
		for (let ref of refs) {
			const instance = this.context.getInstance({__ref__: ref});
			const node: ModelNode = new ModelNode(instance, subnode, this.getDefaultModelRenderer());
			node.setContext(this.context);
			node.setScene(this.sceneData);
			subnode.add(node);
			queue.push({ node, logic });
		}
		return queue;
	}

	protected createRootNode(): ModelNode {
		const node = new ModelNode(this.context.getRootInstance());
		node.setContext(this.context);
		node.setScene(this.sceneData);
		return node;
	}

	protected getDefaultModelRenderer(): SceneRenderer {
		return SceneRenderer.SIMPLE;
	}

	protected static createNode(type: string, parent: Node, model: Model, data: any): Node {
		switch (type) {
			case 'meta':
				return new MetaNode(model, parent, data)
			case 'parallel':
				return new GroupNode(model, parent, type, data)
			case 'exec':
				return new ExecNode(model, parent, data.fn);
			case 'input':
				return new InputNode(model, parent, data);
			default:
				throw new Error(`Unknown node type: ${type}`);
		}
	}

	protected static processGroupNode(parent: Node, node: GroupNode): QueueItem[] {
		parent.add(node);
		return [{ node: node, logic: node.getGroupData() }];
	}

	async debug() {
		console.log('+------------------------------------ DEBUG -------------------------------------');
		await TreeTraversal.depth<Node>(
			this.root(),
			{
				subnodesAccessor: (node: Node) => { return node.getSubnodes(); },
				onNode: (node: Node, next: () => void, context: TraversalContext<Node>) => {
					console.log('+--' + '--'.repeat(context.level) + 'NODE', node.toString());
					next();
				},
			});
		console.log('+--------------------------------------------------------------------------------');
	}
}
