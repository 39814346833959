import {Model} from '../domain/Model';
import {TypeInfo} from './TypeInfo';
import {TriggerEvent} from '../exec/TriggerEvent';
import {Executor} from '../exec/Executor';

export interface ComputeTrigger {
	model: string;
	field: string;
	condition: TriggerEvent;
}

export class ComputeType extends TypeInfo {
	private exe: Executor = new Executor();
	private triggers: ComputeTrigger[] = [];

	constructor() {
		super();
	}

	setFunctionSource(fn: string) {
		this.exe.setSource(fn);
	}

	getFunctionSource(): string {
		return this.exe.getSource() || '';
	}

	getDefaultValue(): any {
		return null;
	}

	getTriggers(): ComputeTrigger[] {
		return this.triggers;
	}

	toString(): string {
		return 'compute';
	}

	execute(context: any, executor: { model: Model; field: string; }): any {
		try {
			return this.exe.execute(context);
		}
		catch (err) {
			console.warn(`ComputeType: caught exception while computing: ${executor.model.getName()}.${executor.field}`);
			console.warn(err);
			return null;
		}
	}

	load(data: any) {
		if ('fn' in data) {
			this.setFunctionSource(data.fn);
		}

		for (let event of Object.values(TriggerEvent)) {
			if (!data[event])
				continue;

			if (!Array.isArray(data[event])) {
				console.warn(`Trigger event ${event}: expected Array, but got:`, data[event]);
				continue;
			}

			for (let ref of data[event]) {
				const [ model, field ] = ref.split('.');
				const trig: ComputeTrigger = {
					model: model.trim(), field: field.trim(), condition: event
				};
				this.triggers.push(trig);
			}
		}
	}
}
