import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ConfiguratorService} from "../configurator.service";
import {Context} from "../context.service";
import {APIService} from "../api.service";
import {Node} from "../nodes/Node";

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
	public loaded: boolean = false;
	public finished: boolean = false;

	@ViewChild('main', { read: ViewContainerRef }) viewContainerRef!: ViewContainerRef;

	constructor(
		private configurator: ConfiguratorService,
		private context: Context,
		private api: APIService,
	) {}

	ngOnInit(): void {
		this.configurator.onLoaded().subscribe((_root: Node) => {
			this.loaded = true;
			this.rebuild();
		});
	}

	rebuild() {
		if (!this.loaded)
			return;

		console.log('[+] Forming ;)');
		this.finished = false;
		this.viewContainerRef.clear();

		const scene = this.configurator.getScene();
		const component = scene.getRendererComponent(this.viewContainerRef);
		component.instance.setNode(scene.root());
	}

	update() {
		console.log('[+] main::update');
	}
}
