import { Widget } from './widget';

export class BoolWidget extends Widget {
	type: string = 'bool';

	constructor(options: {
		value?: boolean;
		name?: string;
		label?: string;
		required?: boolean;
	} = {}) {
		super(options);
	}
}
