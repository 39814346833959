import { Field } from '../domain/Field';
import { Widget } from '../widget/widget';
import { WidgetFactory } from '../widget/WidgetFactory';
import { TypeInfo } from './TypeInfo';

export class NumericType extends TypeInfo {
	protected min: number = -Infinity;
	protected max: number = Infinity;
	protected step!: number;

	constructor() {
		super();

		this.step = this.getDefaultStep();
	}

	isFreeInput(): boolean {
		return false;
	}

	canAcceptValue(value: any): boolean {
		let val = value;
		if (typeof(val) === 'string')
			val = parseFloat(val);
		if (typeof(val) != 'number' || isNaN(val))
			return false;
		return !(val < this.min || val > this.max);
	}

	getDefaultStep(): number {
		return 1;
	}

	getMin(): number {
		return this.min;
	}

	getMax(): number {
		return this.max;
	}

	getStep(): number {
		return this.step;
	}

	setMin(value: number) {
		this.min = value;
	}

	setMax(value: number) {
		this.max = value;
	}

	setStep(value: number) {
		this.step = value;
	}

	getDefaultValue(): any {
		if (Number.isFinite(this.min))
			return this.min;
		return 0;
	}

	createWidget(field: Field): Widget {
		return WidgetFactory.createNumeric(field);
	}

	toString(): string {
		return 'numeric';
	}
}

export class PositiveInteger extends NumericType {
	constructor() {
		super();
		this.min = 0;
	}

	getDefaultValue(): any {
		return 0;
	}

	toString(): string {
		return 'PositiveInteger';
	}
}
