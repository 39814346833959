import {OnInit, Component, Input, Output, EventEmitter} from '@angular/core';
import {SceneComponent} from "../scene/SceneComponent";
import {Node} from "../nodes/Node";
import {ExecNode} from "../nodes/ExecNode";
import {InputNode} from "../nodes/InputNode";
import {PatchRequest} from "../api/PatchRequest";
import {ListType} from "../types/ListType";
import {ListWidget} from "../widget/widget.list";
import {ExecAPI} from "../exec/ExecAPI";
import {Context} from "../context.service";

@Component({
	selector: 'app-form-simple',
	templateUrl: './form-simple.component.html',
	styleUrls: ['./form-simple.component.scss']
})
export class FormSimpleComponent implements OnInit, SceneComponent {
	@Input() node: Node | null = null;
	@Output() created = new EventEmitter<FormSimpleComponent>();

	public valid: boolean = false;
	public inputs: InputNode[] = [];
	private execNodes: ExecNode[] = [];

	constructor(private context: Context) { }

	ngOnInit(): void {
		if (this.node) {
			this.collect();
			this.created.emit(this);
		}
	}

	setNode(node: Node): void {
		this.node = node;
	}

	validate(): boolean {
		this.valid = false;
		for (let node of this.inputs) {
			if (!node.getInputType().required)
				continue;
			if (!node.hasValue())
				return false;
			// TODO: make this work properly
			// if (!node.getWidget().isValid())
			// 	return false;
		}

		this.valid = true;
		return this.valid;
	}

	serialize(): PatchRequest[] {
		const changed: { [key: string]: PatchRequest } = {};
		const patches = [];

		for (let node of this.inputs) {
			if (node.getField().getType().isReadOnly()) {
				continue;
			}

			const model = node.getModel();
			const modelId = model.getId();
			if (!modelId) {
				throw new Error(`Model ID is null for type '${model.getName()}'`);
			}

			if (!(modelId in changed)) {
				changed[modelId] = {
					id: modelId,
					model: model.getName(),
					patch: {},
				};
			}

			changed[modelId].patch[node.getFieldName()] = node.serialize().value;

			if (node.getInputType() instanceof ListType) {
				const widget = node.getWidget() as ListWidget;
				const component = widget.getCurrentComponent();
				if (component) {
					patches.push(...component.serialize());
				}
			}

			// TODO: handle SelectType
		}

		patches.push(...Object.values(changed));
		return patches;
	}

	execute() {
		for (let node of this.execNodes) {
			let api = new ExecAPI(this.context, node);
			node.execute(this.context.getState()!, api);
			this.context?.changed(api.commit());
		}
	}

	onResize(): void {}

	private collect() {
		for (let node of this.node!.getSubnodes()) {
			if (node instanceof InputNode) {
				this.inputs.push(node);
			}
			else if (node instanceof ExecNode) {
				this.execNodes.push(node);
			}
		}
	}
}
