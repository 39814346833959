import { FormControl, Validators } from '@angular/forms';
import { InputNode } from '../nodes/InputNode';
import {ValidityState} from "../api/StateResponse";
import {SceneComponent} from "../scene/SceneComponent";

export class Widget {
	type: string;
	value: any;
	name: string;
	label: string;
	required: boolean;
	id: string = '';
	enabled: boolean = true;
	noValueLabel: string = 'None';

	formControl: FormControl;
	validity?: ValidityState;

	protected node: InputNode | null = null;
	protected blocked: boolean = false;
	protected timer: any = null;

	constructor(options: {
		type?: string;
		value?: any;
		name?: string;
		label?: string;
		required?: boolean;
	} = {}) {
		this.type = options.type || '';
		this.value = options.value;
		this.name = options.name || '';
		this.label = options.label || '';
		this.required = !!options.required;

		this.formControl = this.required ?
			new FormControl({value: this.value, disabled: !this.enabled}, Validators.required) :
			new FormControl({value: this.value, disabled: !this.enabled});
	}

	getValue(): any {
		return this.value;
	}

	setValue(value: any) {
		this.value = value;
		if (this.blocked) {
			return;
		}
		this.onChange(value);
	}

	setEnabled(enabled: boolean) {
		this.enabled = enabled;
		if (this.formControl) {
			if (enabled)
				this.formControl.enable();
			else
				this.formControl.disable();
		}
	}

	setInputNode(node: InputNode) {
		this.node = node;
	}

	getInputNode(): InputNode | null {
		return this.node;
	}

	onChange(event: any) {
		if (this.blocked) {
			return;
		}

		if (this.node)
			this.node.onWidgetChange(event);
	}

	onChangeDebounced(event: any) {
		if (this.timer)
			clearTimeout(this.timer);

		let self = this;
		this.timer = setTimeout(function () {
			self.onChange(event);
		}, 500);
	}

	block() {
		this.blocked = true;
	}

	unblock() {
		this.blocked = false;
	}

	setValidityState(validity: ValidityState) {
		this.validity = validity;
	}

	isValid(): boolean {
		if (this.validity) {
			return this.validity.valid;
		}
		return true;
	}

	onRecomputeBounds() {}
}
