import { Field } from '../domain/Field';
import { Widget } from '../widget/widget';
import { WidgetFactory } from '../widget/WidgetFactory';

export class TypeInfo {
	private _required: boolean = true;

	constructor() {}

	get required(): boolean {
		return this._required;
	}

	setRequired(value: boolean) {
		this._required = value;
	}

	isReadOnly(): boolean {
		return false;
	}

	isFreeInput(): boolean {
		return false;
	}

	canAcceptValue(value: any): boolean {
		return false;
	}

	getInitialValue(): any {
		if (this._required) {
			return this.getDefaultValue();
		}
		return this.getEmptyValue();
	}

	getEmptyValue(): any {
		return null;
	}

	getDefaultValue(): any {
		return null; // Base type defaults to null
	}

	upcast<T extends TypeInfo>(type: new () => T): T | undefined {
		if (this instanceof type)
			return this as T;
		return undefined;
	}

	toString(): string {
		throw new Error('Abstract TypeInfo.toString() called');
	}

	createWidget(field: Field): Widget {
		return WidgetFactory.createText(field);
	}
}
