<div
	*ngIf="visualizers.length > 0"
	class="visualizers"
	[class.layout-top]="visualizerPosition === 'top'"
	[class.layout-side]="visualizerPosition === 'side'">
	<div *ngFor="let vis of visualizers" class="visualizer-wrapper" [innerHTML]="vis">
	</div>
</div>
<div class="wizard-panel" *ngIf="!finished">
	<mat-stepper linear #stepper (selectionChange)="onStepperSelect($event)" orientation="vertical">
		<mat-step
			*ngFor="let step of steps | activeWizardStep; let offset = index"
			[completed]="step.completed"
			[label]="step.root?.getMetadata('title')">
			<div>
				<app-form-simple [node]="step.root" (created)="onStepCreated(step, $event)"></app-form-simple>
				<div class="controls">
					<button
						mat-raised-button color="primary"
						matStepperPrevious
						[disabled]="step.index === firstStepIndex">{{ 'BACK' | translate }}</button>&nbsp;
					<button
						mat-raised-button color="primary"
						matStepperNext
						[disabled]="!step.completed"
						*ngIf="step.index !== lastStepIndex">{{ 'NEXT' | translate }}</button>
					<button
						mat-raised-button color="primary"
						[disabled]="!step.completed"
						*ngIf="step.index === lastStepIndex"
						(click)="finish()">{{ 'FINISH' | translate }}</button>
				</div>
			</div>
		</mat-step>
	</mat-stepper>
</div>
