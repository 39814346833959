<!-- TODO: expose gutterSize, rowHeight, cols as configurable options in main.yml -->
<mat-grid-list cols="3" rowHeight="2:1" gutterSize="8" class="root-select-grid">
	<mat-grid-tile
		*ngFor="let model of candidates"
		(click)="onRootSelect(model)"
		[class.image-tile] = "!!model.thumbnail"
		[class.just-tile] = "!model.thumbnail"
		[style.background]="model.thumbnail ? 'url(' + model.thumbnail + ')' : 'none'">
		<button mat-stroked-button style="width: 100% !important; height: 100% !important;">
			{{ model.getTitle() }}
		</button>
	</mat-grid-tile>
</mat-grid-list>

<!-- TODO: expose legacy mode in options -->
<!--<ul class="multi-root-panel">-->
<!--	<label id="label-select-root-model">{{ 'SELECT_ROOT' | translate }}</label>-->
<!--	<mat-radio-group-->
<!--		aria-labelledby="label-select-root-model"-->
<!--		class="radio-group"-->
<!--		color="warn"-->
<!--		(change)="onChange()"-->
<!--		[(ngModel)]="selected">-->
<!--		<mat-radio-button class="radio-button" *ngFor="let model of candidates" [value]="model">-->
<!--			<div class="model-title">{{ model.getTitle() }}</div>-->
<!--			<img *ngIf="model.thumbnail" [src]="model.thumbnail" />-->
<!--		</mat-radio-button>-->
<!--	</mat-radio-group>-->
<!--</ul>-->
