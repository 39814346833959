<div
	*ngIf="visualizers.length > 0"
	class="visualizers"
	[class.layout-top]="visualizerPosition === 'top'"
	[class.layout-side]="visualizerPosition === 'side'">
	<div *ngFor="let vis of visualizers" class="visualizer-wrapper" [innerHTML]="vis">
	</div>
</div>
<div class="form-grid" *ngIf="!finished && form" [class.layout-half]="visualizerPosition === 'side'">
	<div *ngFor="let section of form.sections; let i = index" class="form-section">
		<fieldset class="section-header" (click)="onSectionClick(section)">
			<legend>
				<mat-icon *ngIf="!section.__ui_open">chevron_right</mat-icon>
				<mat-icon *ngIf="section.__ui_open">expand_more</mat-icon>
				<span>{{ section.title }}</span>
			</legend>
		</fieldset>
		<gridstack
			#grids
			[hidden]="!section.__ui_open"
			[attr.data-model]="form.model"
			[attr.data-section]="i"
			class="form-gs"
			[options]="section.options!">

			<ng-template ngFor let-gridItem [ngForOf]="section.grid"
				let-i="index" [ngForTrackBy]="getWidgetId">
				<gridstack-item
					[options]="gridItem"
					[attr.data-model]="gridItem.model"
					[attr.data-field]="gridItem.field"
					[attr.data-type]="gridItem.type"
					*ngIf="getNode(gridItem)">
					<!--suppress JSUnresolvedReference, TypeScriptValidateJSTypes -->
					<app-widget [widget]="getNode(gridItem)!.getWidget()" [enabled]="getNode(gridItem)!.isEnabled()"></app-widget>
				</gridstack-item>
			</ng-template>
		</gridstack>
	</div>
	<div class="controls" *ngIf="!isChild">
		<button
			mat-raised-button color="primary"
			[disabled]="!valid"
			(click)="finish()">{{ 'FINISH' | translate }}</button>
	</div>
</div>
