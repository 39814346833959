import { Widget } from './widget';

export class ColorWidget extends Widget {
	type: string = 'color';
	defaultColor: string = '#000000';

	constructor(options: {
		value?: string;
		name?: string;
		label?: string;
		required?: boolean;
		defaultColor?: string;
	} = {}) {
		super(options);
		this.defaultColor = options.defaultColor || '#000000';
		if (!this.value) {
			this.value = this.defaultColor;
		}
	}
}
