import { Field } from '../domain/Field';
import { Model } from '../domain/Model';
import { TriggerEvent } from '../exec/TriggerEvent';
import { PresetType } from '../types/PresetType';
import { TypeInfo } from '../types/TypeInfo';
import { ModelNode } from './ModelNode';
import { Node, NodeType, SerializedType } from './Node';
import {PatchRequest} from "../api/PatchRequest";
import {ExecAPI} from "../exec/ExecAPI";

export interface StyleParams {
	width?: string;
}

export class InputNode extends Node {
	private readonly inputType!: TypeInfo;
	private readonly title: string | null = null;
	private readonly field: Field;
	private readonly style: StyleParams = {};

	constructor(protected model: Model, protected parent: Node, data: any) {
		super(NodeType.INPUT, parent, model);

		if (typeof(data) === 'string') {
			data = {
				field: data,
				title: this.autoTitle(data),
			}
		}

		if (!data.field) {
			throw new Error(`Model ${this.model.getName()} contains an input node without a field`);
		}

		if (!this.model.has(data.field)) {
			throw new Error(`Model ${this.model.getName()} has no field: '${data.field}'`);
		}

		this.field = this.model.getField(data.field)!;
		this.field.addNodeRef(this);

		this.inputType = this.field.getType();
		this.widget = this.field.getWidget();

		// Override parameters controlled by InputNode
		if ('title' in data) {
			this.title = data.title;
		}
		else {
			this.title = this.autoTitle(data.field);
		}

		if ('width' in data) {
			this.style.width = data.width;
		}
		// TODO: support more style attributes

		if ('none' in data) {
			this.widget.noValueLabel = data.none;
		}

		if ('enabled' in data) {
			this.widget.setEnabled(!!data.enabled);
		}

		// In case the field is disabled by model
		if (!this.field.isEnabled()) {
			this.widget.setEnabled(false);
		}

		// Load user metadata
		if ('meta' in data) {
			this.loadMetadata(data.meta);
		}

		// Shortcut for node.enabled
		if ('hidden' in data) {
			this.meta.set('enabled', !data.hidden);
		}

		// Set automatic metadata
		this.meta.set('field', this.field.getName());

		this.widget.label = this.getTitle();
		this.widget.setInputNode(this);

		this.loadEventHandlers(data);
	}

	getStyleString(): string {
		let parts = [];
		for (let key in this.style) {
			parts.push(`${key}: ${(<any>this.style)[key]};`);
		}
		return parts.join(' ');
	}

	enterSubtree(): boolean {
		if (this.isLeaf())
			return false;

		return this.widget.getValue();
	}

	// to be deprecated - I think
	getSelectedSubnodes(): Node[] {
		if (!this.enterSubtree())
			return [];

		const value: any = this.widget.getValue();
		const selected: string[] = Array.isArray(value) ? value : [value];

		const subnodes: Node[] = [];
		for (let node of this.subnodes) {
			if (node instanceof ModelNode && selected.indexOf((<ModelNode>node).getModel().getName()) != -1) {
				subnodes.push(node);
			}
		}
		return subnodes;
	}

	getField(): Field {
		return this.field;
	}

	getFieldName(): string {
		return this.field.getName();
	}

	getInputType(): TypeInfo {
		return this.inputType;
	}

	hasValue(): boolean {
		return this.widget.getValue() !== null && this.widget.getValue() !== '';
	}

	getTitle(): string {
		if (this.title)
			return this.title;
		return this.field.getLabel();
	}

	onWidgetChange(event: any) {
		// Ignore widget updates for read-only fields
		if (this.field.getType().isReadOnly())
			return;

		if (!this.context)
			return;

		const value = this.serialize().value;

		if (!this.field.canAcceptValue(value)) {
			console.log('Field "%s" cannot accept value:', this.getFieldName(), value);
			// TODO: set widget to error
			return;
		}

		// Updates field value
		this.field.onChange(event);

		// Triggers validation on renderer
		this.context.onValidate().emit(this.field);

		// Create state update
		const pr: PatchRequest = {
			id: this.getModel().getId()!,
			model: this.getModel().getName(),
			patch: {},
		};
		pr.patch[this.getFieldName()] = this.serialize().value;

		// Trigger state change (patch sent to spencer)
		const api = new ExecAPI(this.context, this);
		this.context.changed([pr]).then(() => {
			const onChange = this.handlers.get(TriggerEvent.ON_CHANGE);
			if (onChange) {
				for (let handler of onChange) {
					handler.execute(this.model, this.context!.getState(), api);
					this.context?.changed(api.commit());
				}
			}
		});

		// Not ported yet
		// if (this.field.getType() instanceof PresetType && this.field.getValue() !== null) {
		// 	const preset = this.context.getDomain().preset((<PresetType>this.field.getType()).getName());
		// 	if (preset)
		// 		this.context.broadcast(preset.getActions(this.field.getValue()));
		// }
	}

	toString(): string {
		return `InputNode(model=${this.model.getName()}; field=${this.field.getName()}; type=${this.inputType.toString()})`;
	}

	serialize(): SerializedType {
		const value = this.widget.getValue();
		return {
			key: this.field.getName(),
			value: value,
		};
	}

	private autoTitle(field_name: string) {
		let tokens = [field_name];
		if (field_name.includes('_')) {
			tokens = field_name.split('_');
		}
		else {
			tokens = field_name.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
		}
		return tokens.reduce((acc, x) => acc + ' ' + x.charAt(0).toUpperCase() + x.substring(1).toLowerCase(), '');
	}
}
