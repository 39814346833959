import { Field } from '../domain/Field';
import { Widget } from '../widget/widget';
import { WidgetFactory } from '../widget/WidgetFactory';
import { TypeInfo } from './TypeInfo';

export class DateType extends TypeInfo {
	constructor() {
		super();
	}

	canAcceptValue(value: any): boolean {
		// TODO: test if actually date value
		return typeof(value) == 'string';
	}

	isFreeInput(): boolean {
		return true;
	}

	getDefaultValue(): any {
		return '1900-01-01';
	}

	toString(): string {
		return 'date';
	}

	createWidget(field: Field): Widget {
		return WidgetFactory.createDate(field);
	}
}
